<template>
    <div class="warning-panel-name">
        <div
            :style="`left: -56px`"
            class="detail-title">
            <img
                class="icon-back"
                @click="backToList"
                src="/img/project/icon-back.png"
                alt=""
            />
            {{ title }}
        </div>
        <div class="warning-search">
            <el-form ref="form" :model="searchForm" size="mini" inline>
                <el-form-item label="">
                    <el-select
                        @change="getWarningList"
                        style="width: 279px"
                        v-model="searchForm.alarmType"
                        placeholder="请选择类型">
                        <el-option
                            v-for="item in warningTaskList"
                            :key="item.dictKey"
                            :label="item.dictValue"
                            :value="item.dictKey">
                        </el-option>
                    </el-select>
                </el-form-item>
                <!-- <el-form-item label="">
                    <el-select 
                        @change="getWarningList"
                        style="width: 125px"
                        v-model="searchForm.taskId" 
                        placeholder="请选择任务">
                        <el-option
                            v-for="item in taskList"
                            :key="item.id"
                            :label="item.taskName"
                            :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item> -->
                <el-form-item label="">
                    <el-date-picker
                        style="width: 280px"
                        :default-time="['00:00:00','23:59:59']"
                        @change="getWarningList"
                        v-model="searchForm.dateRange"
                        type="datetimerange"
                        range-separator="至"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期">
                    </el-date-picker>
                </el-form-item>
            </el-form>
        </div>
        <div 
            v-loading="loading"
            element-loading-text="加载中，请稍后"
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0, 0.8)"
            class="warning-item-box">
            <div
                class="warning-item"
                v-for="item in warningList"
                :key="item.id"
                :class="selectWarning.id === item.id? 'warning-item-active':''"
                @click="chooseWarning(item)">
                <div class="warning-title">
                    <span>{{item.alarmTypeName}}</span>
                    <span class="control-icon">
                        <i 
                            @click.stop="moveTo(item)"
                            v-if="item.id===selectWarning.id"
                            style="margin-right: 4px"
                            class="el-icon-aim"></i>
                        <i 
                            @click.stop="showDialog(item)"
                            v-if="item.id===selectWarning.id"
                            style="margin-right: 4px"
                            class="el-icon-s-order"></i>
                        <i 
                            @click.stop="needShowWarning(item)"
                            :class="item.show? 'warning-show': ''"
                            class="el-icon-view"></i>
                    </span>
                </div>
                <div class="warning-task">{{item.taskName }} [{{item.uavName? item.uavName.join(','): ''}}]</div>
                <el-tooltip :content="item.address" placement="right">
                    <div class="warning-describe">{{ item.address }}</div>
                </el-tooltip>
                <div class="warning-time">{{ item.alarmTime }}</div>
            </div>
        </div>
        <div class="left-table-pagination-line">
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page.sync="page"
                :page-size="size"
                :pager-count="3"
                size="small"
                background
                layout="total, prev, pager, next"
                :total="total"/>
        </div>
        <el-dialog
            title="报警详情"
            class="common-map-dialog"
            :visible.sync="showWarningDetialDialog"
            width="960"
            :append-to-body="true"
            :close-on-click-modal="false"
            :before-close="handleClose">
            <div class="warning-detail-info">
                <div class="detail-info-left">
                    <div class="text-line">
                        <span class="info-title">所属项目</span>
                        <span class="info-content">{{detailNode.projectName}}</span>
                    </div>
                    <div class="text-line">
                        <span class="info-title">报警类型</span>
                        <span class="info-content">{{detailNode.alarmTypeName}}</span>
                    </div>
                    <div class="text-line">
                        <span class="info-title">报警设备</span>
                        <span class="info-content">{{detailNode.uavName? detailNode.uavName.join(','): ''}}</span>
                    </div>
                    <div class="text-line">
                        <span class="info-title">经纬度</span>
                        <span class="info-content">[{{ Number( detailNode.lon).toFixed(7) }}, {{Number(detailNode.lat).toFixed(7)}} ]</span>
                    </div>
                </div>
                <div class="detail-info-reight">
                    <div class="text-line">
                        <span class="info-title">所属任务</span>
                        <span class="info-content">{{detailNode.taskName}}</span>
                    </div>
                    <div class="text-line">
                        <span class="info-title">报警地点</span>
                        <span class="info-content">{{detailNode.address}}</span>
                    </div>
                    <div class="text-line">
                        <span class="info-title">报警时间</span>
                        <span class="info-content">{{detailNode.alarmTime}}</span>
                    </div>
                    <div class="text-line">
                        <span class="info-title">报警照片</span>
                        <span class="info-content">
                            <el-image 
                                :z-index="9999"
                                style="width: 96px; margin-right: 8px"
                                v-for="item in detailNode.imgs"
                                fit="fill"
                                @close="handleClose"
                                @click="showImgDialog(item)"
                                :key="item"
                                :src="item"
                                :preview-src-list="detailNode.imgs">
                            </el-image>
                        </span>
                    </div> 
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { getDictionary } from "@/api/system/dictbiz";
import { getAlarmData, getProjectDetail } from "@/api/project/index";

export default {
    name: "warningPanel",
    props: {
        type: {
            type: String,
            default() {
                return null;
            },
        }
    },
    data() {
        return {
            // 飞行项目信息
            projectInfo: {},
            // 搜索表达
            searchForm: {
                taskId: '',
                alarmType: '',
                dateRange: ''
            },
            warningTaskList: [],
            taskList: [],
            size: 5,
            page: 1,
            total: 0,
            detialDialogVisible: false,
            detailNode: {},
            imgDialogVisible: false,
            loading: true
        }
    },
    computed: {
        ...mapGetters([
            "warningList",
            "selectWarning",
            'showWarningDetialDialog'
        ]),
        title() {
            return this.$route.query.projectName || this.projectInfo.projectName
        }
    },
    mounted() {
        // 获取项目信息
        this.getProjectDetail()
    },
    methods: {
        getProjectDetail() {
            const id = this.$route.query.projectId
            getProjectDetail({id}).then((res) => {
                if (res.data.code === 200) {
                    this.projectInfo = res.data.data;
                    // 获取报警类型列表
                    this.getDictList()
                    // 获取报警信息
                    this.getWarningList()
                }
            });
        },
        backToList() {
            this.$router.push('/forest-grass/FG-Inspection');
        },
        chooseWarning (node) {
            this.$store.dispatch("setSelectWarning", node);
            this.showDetail()
        },
        needShowWarning (node) {
            this.$store.dispatch("setWarningList", this.warningList.map(item=>{
                if (item.id === node.id) {
                    item.show = !item.show
                }
                return item
            }));
        },
        showDetail (node) {
            this.$EventBus.$emit('warning-event', {
                type: 'detail',
                data: node
            })
        },

        moveTo (node) {
            this.$store.dispatch("setWarningList", this.warningList.map(item=>{
                if (item.id === node.id) {
                    item.show = true
                }
                return item
            }));
            this.showDetail(node)
            setTimeout(()=>{
                this.$EventBus.$emit('warning-event', {
                    type: 'moveTo',
                    data: node
                })
            }, 0)
        },

        getDictList() {
            getDictionary({
                code: 'ForestandGrassPatrolInspection',
            }).then(res=>{
                res.data.data.unshift({
                    dictValue: '所有类型',
                    dictKey: ''
                })
                this.warningTaskList = res.data.data
            })
        },

        getWarningList() {
            let startTime, endTime;
            if ( Array.isArray(this.searchForm.dateRange) && this.searchForm.dateRange.length === 2) {
                startTime = this.searchForm.dateRange[0]
                endTime = this.searchForm.dateRange[1]
            }
            let projectId = this.$route.query.projectId
            let params = {
                projectId,
                taskId: this.searchForm.taskId,
                page: this.page,
                size: this.size,
                alarmType: this.searchForm.alarmType,
                startTime,
                endTime
            }
            this.loading = true
            getAlarmData(params).then(res=>{
                let data = res.data.data;
                this.total = data.total;
                this.$store.dispatch("setWarningList", data.records.map(item=>{
                    item.lng = Number(item.lon)
                    item.lat = Number(item.lat)
                    item.show = true
                    item.imgs = item.picUrl? item.picUrl.split(','): []
                    return item
                }));
            }).finally(()=>{
                this.loading = false
            })
        },

        handleSizeChange(v) {
            this.size = v
            this.page = 1
            this.getWarningList()
        },

        handleCurrentChange(v) {
            this.page = v
            this.getWarningList()
        },

        showDialog(node) {
            this.detailNode = node
            this.$store.dispatch('setShowWarningDetialDialog', true)
        },

        handleClose() {
            this.detialDialogVisible = false
            this.$store.dispatch('setShowWarningDetialDialog', false)
        },

        showImgDialog() {
            this.$nextTick(() => {
                let domImageView = document.querySelector(".el-image-viewer__mask"); // 获取遮罩层dom
                let closeBtn = document.querySelector(".el-image-viewer__close");
                let domDialogView = document.querySelector(".common-map-dialog"); 
                if (!domImageView && !closeBtn) {
                    return;
                }
                domDialogView.style.opacity = 0
                domImageView.addEventListener("mouseup", this.handleImgClose);
                closeBtn.addEventListener("mouseup", this.handleImgClose);
            });
        },

        handleImgClose() {
            let domImageView = document.querySelector(".el-image-viewer__mask"); 
            let closeBtn = document.querySelector(".el-image-viewer__close")
            let domDialogView = document.querySelector(".common-map-dialog"); 
            if (!domImageView && !closeBtn && !domDialogView) {
                return;
            }
            domDialogView.style.opacity = 1
            domImageView.removeEventListener("click", this.handleClose);
            closeBtn.removeEventListener("click", this.handleClose);
        },
    }
}
</script>

<style lang="scss">
.warning-panel-name {
    position: relative;
    color: #FFFFFF;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .detail-title {
        position: absolute;
        top: -49px;
        font-size: 14px;
        font-family: MicrosoftYaHeiUI-Bold, MicrosoftYaHeiUI;
        font-weight: bold;
        color: #ffffff;
        padding-top: 9px;
        padding-bottom: 11px;
        display: flex;
        align-items: center;
        .icon-back {
        margin-right: 4px;
        }
    }
    .warning-search {
        padding: 5px;
        border-bottom: 1px solid #030303;
    }
    
    .warning-item-box{
        flex-grow: 1;
        overflow: auto;
        .warning-item {
            cursor: pointer;
            height: 126px;
            box-sizing: border-box;
            border-bottom: 1px solid #030303;
            padding: 16px 10px;
            .warning-title {
                font-size: 14px;
                font-family: MicrosoftYaHeiUI-Bold, MicrosoftYaHeiUI;
                font-weight: bold;
                color: #FFFFFF;
                line-height: 18px;
                display: flex;
                justify-content: space-between;
                .control-icon{
                    color: rgba(153,153,153,0.79);
                }
            }
            .warning-task {
                height: 15px;
                margin-top: 16px;
                font-size: 12px;
                font-family: MicrosoftYaHeiUI;
                color: rgba(153,153,153,0.79);
                line-height: 15px;
            }
            .warning-describe {
                height: 15px;
                overflow: hidden;
                white-space:nowrap;
                text-overflow: ellipsis;
                margin-top: 8px;
                font-size: 12px;
                font-family: MicrosoftYaHeiUI;
                color: rgba(153,153,153,0.79);
                line-height: 15px;
            }
            .warning-time {
                height: 15px;
                margin-top: 8px;
                font-size: 12px;
                font-family: MicrosoftYaHeiUI;
                color: rgba(153,153,153,0.79);
                line-height: 15px;
            }
            .warning-show {
                color: #3573FF;
            }
        }
    }
    
    .warning-item-active {
        background-color: #3573FF33;
    }

    // 局部样式以后会统一 zcj
    .el-select {
      .el-input {
        .el-input__inner {
          background-color: #030303;
          color: #C0C4CC;
        }
        .el-select__caret {}
      }
    }

    // 局部样式以后会统一 zcj
    .el-date-editor{
      background-color: #030303 !important;
      .el-range-separator {
        color: #C0C4CC;
      }
      .el-range-input {
        background-color: transparent;
        color: #C0C4CC;
      }
      .el-range-input::placeholder {
        color: #303133;
      }
    }
}
</style>